import React from 'react'
import './Sell.css'
import { Link } from 'react-router-dom'
const Sell = () => {
    return (
        <div className='container'>
            <div className='row py-2'>
                <div className='col-md-6 mx-auto'>
                    <div className='card bg-light shadow-sm'>
                        <div className='card-body'>
                            <div className='sell__type'>
                                <Link to="/sell-new" style={{ textDecoration: 'none' }}>Sell New</Link>
                                <Link to="/sell-old" style={{ textDecoration: 'none' }}>Sell Old</Link>
                                <Link to="/staff" style={{ textDecoration: 'none' }}>Post as a Vendor</Link>
                                <Link to="/on-rent-form" style={{ textDecoration: 'none' }}>On Rent</Link>
                                <Link to="/job-seeker" style={{ textDecoration: 'none' }}>Post as a Job Seeker</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Sell