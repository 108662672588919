import React from 'react'
import './Footer.css'
import { BsFacebook, BsInstagram, BsTwitter, BsLinkedin } from 'react-icons/bs'
import { Link } from 'react-router-dom'
const Footer = () => {
    return (
        <>
            <div style={{ backgroundColor: 'darkorange' }}>
                <div className='container'>
                    <div className='row footer__container '>
                        <div className='col-md-2 col-6 '>
                            <h5 className='footer__heading'>Popular Cities</h5>
                            <ul className='m-0 p-0'>
                                <li><a href="#">Lucknow</a></li>
                                <li><a href="#">Kanpur</a></li>
                                <li><a href="#">Gorakhpur</a></li>
                                <li><a href="#">Ahmedabad</a></li>
                                <li><a href="#">Varanasi</a></li>
                                <li><a href="#">Prayagraj</a></li>
                            </ul>
                        </div>
                        <div className='col-md-2 col-6'>
                            <h5 className='footer__heading'>Event Bazaar</h5>
                            <ul className='m-0 p-0'>
                                <li><a href="#">Help Blog</a></li>
                                <li><a href="#">Legal and Privacy Information</a></li>
                                <li>
                                    <Link to='/contact-us'>Contact Us.</Link>
                                </li>

                            </ul>
                        </div>
                        <div className='col-md-3 col-6'>
                            <h5 className='footer__heading'>Category We Serve</h5>
                            <ul className='m-0 p-0'>
                                <li><a href="#">Tent & Furniture</a></li>
                                <li><a href="#">Catering</a></li>
                                <li><a href="#">Birthday Decoration</a></li>
                                <li><a href="#">Photographar</a></li>
                                <li><a href="#">Sound & DJ</a></li>
                                <li><a href="#">Light Decoration</a></li>
                            </ul>
                        </div>
                        <div className='col-md-2 col-6'>
                            <h5 className='footer__heading'>Trending Search</h5>
                            <ul className='m-0 p-0'>
                                <li><a href="#">Used Oven</a></li>
                                <li><a href="#">Used Chairs</a></li>
                                <li><a href="#">Used Camera</a></li>
                                <li><a href="#">Used Tents</a></li>
                                <li><a href="#">Used LED Counter</a></li>
                                <li><a href="#">Used Speakers</a></li>
                                <li><a href="#">New Birthday Props</a></li>
                            </ul>
                        </div>
                        <div className='col-md-2 col-12'>
                            <div className='follow_us '>
                                <center>
                                    <h5 className='footer__heading'>Follow Us</h5>
                                    <Link to='https://business.facebook.com/to/100063835879002/'> <BsFacebook className='follow_icons' /></Link>
                                    <Link to='https://www.instagram.com/event_planet_india/'><BsInstagram className='follow_icons' /></Link>
                                    <Link to='https://twitter.com/EventPlanet4?t=INTPaTinc9-Kfuu4Qc75zQ&s=09'><BsTwitter className='follow_icons' /></Link>
                                    <Link to='https://www.linkedin.com/company/event-planet-india/mycompany/'><BsLinkedin className='follow_icons' /></Link>
                                </center>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
            <div className='container-fluid'>
                <div className='row copyright__section d-flex justify-content-around'>
                    <div className='col-md-12'>
                        <div className='copy__right'>
                            <div className='copy_right'>
                                &copy; Powered By Event Planet
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Footer