import React, { useEffect, useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import './style.css'
import { BsFillShareFill } from 'react-icons/bs'
import { AiOutlineHeart } from 'react-icons/ai'
import { ImLocation2 } from "react-icons/im";
import { MdDataExploration } from "react-icons/md";
import { MdDateRange } from "react-icons/md";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import MyData from './MyData'
import { Link } from "react-router-dom";
// import required modules
import { v4 as uuidv4 } from 'uuid';
import { Pagination, Navigation, Autoplay } from "swiper";
import { useParams } from "react-router-dom";
import init from "./firebase";
import { doc, getDoc, serverTimestamp, collection, setDoc, query, where, getDocs } from 'firebase/firestore';
import { useUserAuth } from "./context/userAuthContext";
import DateFinder from "./DateFinder";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import IndustrytNameFinder from "./IndustryNameFinder";
import SellerNumberFinder from "./SellerNumberFinder";
import { BsTelegram, BsLinkedin, BsInstagram, BsWhatsapp, BsFacebook } from 'react-icons/bs'
import MerchantNameFinder from "./MerchantNameFinder";
import CityNameFinder from "./CityNameFinder";
const BuyNewPreview = () => {
    const protocol = window.location.protocol //http
    const host = window.location.host //localhost:3000
    const pathname = window.location.pathname // current location
    const [shareLink, setShareLink] = useState(null)
    useEffect(() => {
        const sharableLink = protocol + host + pathname;
        setShareLink(sharableLink)
    }, []);
    const { user } = useUserAuth()
    const buyer_id = user?.uid;
    const { id } = useParams();


    const [card, setCard] = useState(MyData)
    const [data, setData] = useState([]);
    const [timestamp, setTimestamp] = useState(null);

    const postedDate = () => {
        const date = new Date(timestamp * 1000)
        return date.toLocaleDateString();
    }
    const getSingleProduct = async () => {
        try {
            const res = await getDoc(doc(init.db, "products", id));
            setData(res.data());
            setTimestamp(res.data().createdAt.seconds)
        } catch (error) {
            console.log(`Error : ${error}`)
        }
    }
    // const merchantNameFinder = async (merchant_id) => {
    //     try {
    //         const res = await getDoc(doc(init.db, "olx_user", merchant_id));
    //         setMerchantName(res.data().profile.name)
    //     } catch (error) {
    //         console.log(`Error : ${error}`)
    //     }
    // }
    useEffect(() => {
        getSingleProduct()
        // merchantNameFinder(data.merchant_id)
    }, [id, data])

    const isAlreadySentRequest = async (buyer_id, product_id) => {
        const mycollection = collection(init.db, 'olx_requests');
        let q = query(mycollection, where('buyer_id', '==', buyer_id), where('product_id', '==', product_id));
        const data = await getDocs(q);
        return data.docs.length > 0 ? true : false
    }
    const btnHandler = async (seller_id) => {
        if (buyer_id === undefined) {
            toast.error('please login to continue')
        } else if (buyer_id === seller_id) {
            toast.error("You can't send request to your own product")
        } else {
            // alert(`Seller Id ${seller_id} and product is is ${id} and buyer_id ${buyer_id}`)
            const status = isAlreadySentRequest(buyer_id, id)
            if (status) {
                toast.error("You've already sent request to this product")
            } else {
                try {
                    await setDoc(doc(init.db, "olx_requests", uuidv4()), {
                        seller_id,
                        product_id: id,
                        buyer_id,
                        createdAt: serverTimestamp()
                    });
                    toast.success('Request sent successfully.')

                } catch (err) {
                    console.log(`Error ${err}`)
                }
            }
        }

    }
    return (
        <>
            {
                data && (
                    <div className='container'>
                        <div className='row mt-1'>
                            <div className='col-md-8'>
                                <Swiper
                                    pagination={{
                                        type: "fraction",
                                    }}
                                    autoplay={true}
                                    navigation={true}
                                    modules={[Pagination, Navigation, Autoplay]}
                                    className="mySwiper">
                                    {
                                        data.productImages?.map((item, index) => {
                                            return (
                                                <SwiperSlide key={index}>
                                                    <img src={item} alt="EP" />
                                                </SwiperSlide>
                                            )
                                        })
                                    }

                                </Swiper>
                            </div>
                            <div className='col-md-4'>
                                <div className="card">
                                    <div className="card-body">
                                        <div className="d-flex justify-content-between">
                                            <h1 className="">&#8377;
                                                {data?.price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                            </h1>
                                            <div className="">
                                                <button type="button" class="btn btn-white " data-bs-toggle="modal" data-bs-target="#exampleModal">
                                                    <BsFillShareFill className='share_icon' />
                                                </button>
                                                <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                    <div class="modal-dialog  modal-sm modal-dialog-centered">
                                                        <div class="modal-content">
                                                            <div class="modal-header">

                                                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                            </div>
                                                            <div class="modal-body share__options">
                                                                <a href={`https://facebook.com/share.php?u=${shareLink}`}><BsFacebook className='share__icon' style={{ color: "#3b5998" }} /></a>
                                                                <a href={`https://api.whatsapp.com/send?text=${shareLink}`}><BsWhatsapp className='share__icon' style={{ color: "#075e54" }} /></a>
                                                                <a href={`https://www.linkedin.com/sharing/share-offsite/?url=${shareLink}`}><BsLinkedin className='share__icon' style={{ color: "#0e76a8" }} /></a>
                                                                <a href={`https://t.me/share/url?url=${shareLink}&text=${data?.product_name}`}><BsTelegram className='share__icon' style={{ color: " 0088cc" }} /></a>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                                <AiOutlineHeart className='heart_icon' />
                                            </div>
                                        </div>
                                        <h5 className="pro_name">{data?.product_name}</h5>

                                    </div>
                                    <div className="card-footer bg-white">
                                        <div className="d-flex justify-content-around">
                                            <strong><ImLocation2 /> <CityNameFinder city_id={data?.city_id} /></strong>
                                            <strong>
                                                <MdDateRange /> <DateFinder mydate={data.createdAt?.seconds} />
                                            </strong>
                                        </div>
                                    </div>
                                </div>
                                <div className="card mt-3">
                                    <div className="card-header bg-white">
                                        <strong>Seller Profile</strong>
                                    </div>
                                    <div className="card-body">
                                        <div className="d-flex justify-content-around align-items-center mt-2">
                                            <Link to={`/products/${data?.merchant_id}`}><img src="https://e7.pngegg.com/pngimages/799/987/png-clipart-computer-icons-avatar-icon-design-avatar-heroes-computer-wallpaper-thumbnail.png" style={{ height: '50px', width: '50px', borderRadius: '50%' }} /></Link>
                                            <h4><MerchantNameFinder user_id={data?.merchant_id} /></h4>
                                        </div>
                                        <center><button style={{ height: '50px', width: '90%', backgroundColor: 'darkorange', marginTop: '5px', color: '#fff', border: 'none', borderRadius: '10px' }} onClick={() => btnHandler(data?.merchant_id)}> Contact with Seller</button></center>
                                        <center><SellerNumberFinder user_id={data?.merchant_id} /></center>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row my-2">
                            <div className="col-md-12">
                                <div className="card my-2">
                                    <div className="card-header bg-warning">
                                        <strong>Product Detail</strong>
                                    </div>
                                    <div className="card-body">
                                        <table className="table table-bordered">
                                            <tbody>
                                                <tr>
                                                    <th>Industry : <IndustrytNameFinder industry_id={data.industry} /></th>
                                                    <th>Price : {data.price}</th>
                                                </tr>
                                                <tr>
                                                    <th>Size : {data.size}</th>
                                                    <th>Quantity : {data.quantity}</th>
                                                </tr>
                                                <tr>
                                                    <th>Negotiable : {data.negotiable}</th>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header bg-warning">
                                        <strong>Product Description</strong>
                                    </div>
                                    <div className="card-body">
                                        <p>{data.description}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ToastContainer />
                    </div >
                )
            }

        </>
    )
}

export default BuyNewPreview