import React, { useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import 'react-toastify/dist/ReactToastify.css';
import init from './firebase';
import { doc, setDoc, collection, getDocs, addDoc, serverTimestamp } from 'firebase/firestore';
const Contact = () => {
    const [data, setData] = useState({
        name: '',
        mobno: '',
        email: '',
        message: ''
    });
    const formHandler = (e) => {

        const name = e.target.name;
        const value = e.target.value;

        setData({ ...data, [name]: value })

    }
    const SubmitHandler = async (e) => {
        e.preventDefault()

        const {
            name,
            mobno,
            email,
            message
        } = data;
        if (name !== '' && mobno !== '' && email !== '' && message !== '') {
            try {
                const docRef = await setDoc(doc(init.db, "olx_enquiries", uuidv4()), {
                    name,
                    mobno,
                    email,
                    message,
                    createdAt: serverTimestamp()
                });
                setData({
                    name: '',
                    mobno: '',
                    email: '',
                    message: ''
                })
                toast.success('Request sent successfully')

            } catch (err) {
                console.log(`Error ${err}`)
            }
        } else {
            toast.error('All field required')
        }
    }
    return (
        <>
            <div className='container'>
                <div className='row my-4'>
                    <div className='col-md-12'>
                        <center><h3 className='mb-3'>Contact Us.</h3></center>
                        <form onSubmit={SubmitHandler}>
                            <div className="form-floating mb-3">
                                <input type="text" className="form-control" id="floatingInput" placeholder="Your Full Name" value={data.name} onChange={formHandler} name='name' />
                                <label htmlFor="floatingInput">Your Full Name</label>
                            </div>
                            <div className="form-floating mb-3">
                                <input type="tel" className="form-control" id="floatingInput" minLength={10} maxLength={10} placeholder="Enter your Mobile Number" value={data.mobno} onChange={formHandler} name='mobno' />
                                <label htmlFor="floatingInput">Contact Number</label>
                            </div>
                            <div className="form-floating mb-3">
                                <input type="email" className="form-control" id="floatingInput" placeholder="enter your email" value={data.email} onChange={formHandler} name='email' />
                                <label htmlFor="floatingInput">Email address</label>
                            </div>
                            <div className="form-floating">
                                <input type="text" className="form-control" id="floatingPassword" placeholder="Message" value={data.message} onChange={formHandler} name='message' />
                                <label htmlFor="floatingPassword">Message</label>
                            </div>
                            <center><input type="submit" value="Submit" className='btn btn-success my-3' /></center>
                        </form>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </>
    )
}

export default Contact