import React, { useState, useEffect } from 'react'
import init from './firebase';
import { v4 as uuidv4 } from 'uuid';
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import './Sell.css'
import { useUserAuth } from './context/userAuthContext';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { doc, setDoc, collection, getDocs, addDoc, serverTimestamp } from 'firebase/firestore';
const EditOldForm = ({ newData, product_id }) => {
    const { user } = useUserAuth();
    const merchant_id = user?.uid;
    const navigate = useNavigate();
    const [previousImgUrl, setPreviousImgUrl] = useState(null);
    const [previousImgProgress, setPreviousImgProgress] = useState(0);
    const [productImages, setProductImages] = useState([])
    const [data, setData] = useState({
        industry: '',
        city_id:'',
        product_name: '',
        condition: '',
        purchase_date: '',
        selling_price: '',
        description: '',
        quantity: ''
    })
    const formHandler = (e) => {
        e.preventDefault();
        const name = e.target.name;
        const value = e.target.value;
        setData({ ...data, [name]: value })
    }
    const [category, setCategory] = useState()
    const getCategories = async () => {
        const mycollection = collection(init.db, 'olx_categories');
        const data = await getDocs(mycollection);
        setCategory(data.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
    }
    const [city, setCity] = useState()
    // console.log('city list', city)
    const getCity = async () => {
        const mycollection = collection(init.db, 'cities');
        const data = await getDocs(mycollection);
        setCity(data.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
    }
    useEffect(() => {
        getCategories()
        getCity()
        if (!user) {
            navigate('/')
        }
    }, [merchant_id])

    const previousUploader = async (e) => {
        console.log("Image process", e.target.files[0])
        const file = e.target.files[0];
        const fileExtension = file.name.split('.').pop();
        // console.log('File name ' + file + 'fileExtension ' + fileExtension)
        const storageRef = ref(init.storage, `${uuidv4()}.${fileExtension}`);
        const uploadTask = uploadBytesResumable(storageRef, file);
        uploadTask.on('state_changed',
            (snapshot) => {
                const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                //setProgresspercent(progress);
                setPreviousImgProgress(progress)
            },
            (err) => {
                console.log(err);
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    console.log('Uploaded image', downloadURL)
                    setPreviousImgUrl(downloadURL);
                    setProductImages([...productImages, downloadURL])
                });
            }
        );
    }
    const btnHandler = async (e) => {
        e.preventDefault();
        const {
            industry,
            city_id,
            product_name,
            condition,
            purchase_date,
            selling_price,
            description,
            quantity,
        } = data;
        if (industry !== '' && product_name !== '' && condition !== '' && purchase_date !== '' && selling_price !== '' && description !== '' && quantity !== '') {
            try {
                const docRef = await setDoc(doc(init.db, "products", uuidv4()), {
                    merchant_id,
                    city_id,
                    productImages,
                    industry,
                    product_name,
                    condition,
                    purchase_date,
                    selling_price,
                    description,
                    quantity,
                    type: 'old',
                });
                setData({
                    industry: '',
                    city_id:'',
                    product_name: '',
                    condition: '',
                    purchase_date: '',
                    selling_price: '',
                    description: '',
                    quantity: ''
                })
                setProductImages([])
                toast.success('Product Updated successfully.')

            } catch (err) {
                console.log(`Error ${err}`)
            }
        }
        else {
            toast.error('All field required')
        }

    }
    return (
        <div className='container'>
            <form onSubmit={btnHandler}>
                <div className='row mb-2 mt-2 sell__new__container'>

                    <div className="col-md-6 mb-2 mt-2">
                        <label htmlFor="inputPassword4" className="form-label my__color">Industry<span className='text-danger'>*</span></label>
                        <select name="industry" className='form-control' onChange={formHandler} >
                            <option disabled selected>Select Industry</option>
                            {
                                category?.map((item, index) => {
                                    return <option value={item.id} key={index} selected={`${item.id === data.industry ? 'selected' : ''}`}>{item.name}</option>
                                })
                            }
                        </select>
                    </div>
                    <div className="col-md-6 mb-2 mt-2">
                        <label htmlForm="Industry" className="form-label my__color">City<span className='text-danger'>*</span></label>
                        <select className='form-control' name="city_id" onChange={formHandler}>
                            <option selected disabled>Select City</option>
                            {
                                city?.map((item, index) => {
                                    return <option key={index} value={item.id} selected={`${item.id === data.city_id ? 'selected' : ''}`}>{item.city}</option>
                                })
                            }
                        </select>
                    </div>
                    <div className='col-md-6 mb-2 mt-2 mb-3 mt-3mb-2 mt-2'>
                        <div className='form-group'>
                            <label className="form-label my__color" htmlFor="product_name">Product Name<span className='text-danger'>*</span></label>
                            <input type="text" className="form-control" id="product_name" placeholder="Enter Product Name" name="product_name" value={data.product_name} onChange={formHandler} />
                        </div>
                    </div>

                    <div className='col-md-6 mb-2 mt-2'>
                        <label className="form-label my__color" htmlFor="condition">Product Condition</label>
                        <select className="form-select" id="condition" name="condition" onChange={formHandler} >
                            <option selected disabled>Choose Condition</option>
                            <option value="Good Condition">Good Condition</option>
                            <option value="New Condition">New Condition</option>
                            <option value="Sell Pack">Sell Pack</option>
                            <option value="Mint Condition">Mint Condition</option>
                            <option value="Repairable Condition">Repairable Condition</option>
                        </select>
                    </div>


                    <div className="col-md-6 mb-2 mt-2">
                        <label htmlFor="inputEmail4" className="form-label my__color">Purchase date<span className='text-danger'>*</span></label>
                        <input type="Date" className="form-control" name="purchase_date" id="inputEmail4" onChange={formHandler} />
                    </div>

                    <div className="col-md-6 mb-2 mt-2">
                        <label htmlFor="inputEmail4" className="form-label my__color">Purchase price<span className='text-danger'>*</span></label>
                        <input type="number" className="form-control" name="purchase_price" placeholder="Enter Purchase price" id="inputEmail4" onChange={formHandler} />
                    </div>

                    <div className="col-md-6 mb-2 mt-2">
                        <label className="form-label my__color">Selling Price<span className='text-danger'>*</span></label>
                        <input type="Number" className="form-control" placeholder="Enter Selling Price" name="selling_price" onChange={formHandler} />
                    </div>

                    <div className="col-md-6 mb-2 mt-2">
                        <label htmlFor="inputEmail4" className="form-label my__color">Quantity<span className='text-danger'>*</span></label>
                        <input type="text" className="form-control" placeholder="Enter Quantity" name="quantity" onChange={formHandler} />
                    </div>


                    <div className='col-md-12'>

                        <label className="form-label">Product Description <span className='text-danger'>*</span> </label>

                        <textarea className="form-control" rows="4" name="description" onChange={formHandler}></textarea>


                    </div>
                    <div className='row my-2'>
                        <div className='col-md-2 col-6'>
                            <input type="file" id="previous_input" hidden onChange={previousUploader} />
                            <label for="previous_input" className=''>
                                <div style={{ height: '100px', width: '100px', borderRadius: '10px', border: '2px solid rgba(0,0,0,0.3)', margin: '1px', display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'center', color: '#000' }}>
                                    <i className='fa fa-plus' style={{ fontSize: '22px' }}></i>
                                    Add More
                                </div>
                            </label>
                        </div>
                        {
                            productImages?.map((item, index) => {
                                return (
                                    <div className='col-md-2 col-6' key={index}>
                                        <img src={item} style={{ height: '100px', width: '100px', borderRadius: '5px', border: '2px dashed skyblue', boxShadow: '0 3px 5px rgba(0,0,0,0.2)' }} key={index} />
                                    </div>
                                )
                            })
                        }
                    </div>

                    {
                        !previousImgUrl && previousImgProgress ?
                            <div className="progress bg-white">
                                <div className="progress-bar progress-bar-striped bg-success" role="progressbar" style={{ width: `${previousImgProgress}%` }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">{previousImgProgress} %</div>
                            </div>
                            : ''
                    }

                    <div className='col-md-6 mb-2 mt-2'>
                        <div className="col-auto">
                            <button type="submit" className="btn btn-primary mb-3">Submit</button>
                        </div>
                    </div>



                </div>
            </form>
            <ToastContainer />
        </div>

    )
}


export default EditOldForm