import React, { useState, useEffect, memo } from 'react'
import init from './firebase';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { Link, NavLink } from 'react-router-dom';
import './style.css'
import { useUserAuth } from './context/userAuthContext';

const SubHeader = (props) => {

    const { data, setIndustryName, industryName,setIndustryId } = useUserAuth()
    const [cat_id, setCatId] = useState()

    const btnHandler = (industry_id, industry_name) => {
        setCatId(industry_id)
        setIndustryId(industry_id)
        setIndustryName(industry_name)
        props.greet(industry_id)
    }

    return (
        <div className='container'>
            <div className='row'>
                <div className='col-md-12 '>
                    <div className='container sub__header'>
                        <div className='row mt-3'>
                            <div className='col-md-3 '>
                                <div className='old_btn mt-2'>
                                    <div className="dropdown">
                                        <button className="btn category_btn  dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            {
                                                !industryName ? 'Choose Category' : industryName
                                            }
                                        </button>
                                        <ul className="dropdown-menu" >
                                            {
                                                data?.map((cur, index) => {
                                                    return (
                                                        <li key={index}>
                                                            <a
                                                                className="dropdown-item"
                                                                onClick={() => btnHandler(cur.id, cur.name)}
                                                            >{cur.name}</a>
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-9'>
                                <div className='row btn__options'>
                                    <div className='col-md-3 col-6'>
                                        <div className='sell_old'>
                                            <NavLink to="/buy-new">BUY NEW</NavLink>
                                        </div>
                                    </div>
                                    <div className='col-md-3 col-6'>
                                        <div className='sell_old'>
                                            <NavLink to={`/buy-old`} >BUY OLD</NavLink>
                                        </div>
                                    </div>
                                    <div className='col-md-3 col-6'>
                                        <div className='sell_old'>
                                            <NavLink to="/on-rent" >ON RENT</NavLink>
                                        </div>
                                    </div>
                                    <div className='col-md-3 col-6'>
                                        <div className='sell_old'>
                                            <NavLink to='/latest-job'>VENDOR/STAFF</NavLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default memo(SubHeader)