import React, { useState, useEffect } from 'react'
import './style.css'
import { useNavigate } from "react-router-dom";
import init from './firebase';
import { Modal, Button } from 'react-bootstrap';
import { collection, getDocs } from 'firebase/firestore';
import { BsSearchHeartFill, BsPlus, BsSearch } from 'react-icons/bs'
import { GoPlus } from 'react-icons/go'
import MyData from './MyData';
import { Link } from 'react-router-dom';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Navigate } from "react-router-dom";
import { useUserAuth } from './context/userAuthContext';
import Logo from './mylogo.png'
import { useDispatch, useSelector } from 'react-redux'
import { setGlobalCity, setGlobalLocality } from './store/FilterSlice'
const Header = () => {
    const redux = useSelector((state) => {
        return state.filter
    })
    // dispatch(setGlobalCity(id))
    console.log('Store', redux)
    const { user } = useUserAuth();
    console.log(user)
    const [loggedIn, setLoggedIn] = useState(false)
    let navigate = useNavigate();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    // sell state
    const [showSell, setSellShow] = useState(false);

    const handleSellClose = () => setShow(false);
    const handleSellShow = () => setShow(true);
    //sell state end
    const [data, setData] = useState(MyData);

    const [category, setCategory] = useState()
    const [loading, setLoading] = useState(true);
    const getCategories = async () => {
        const mycollection = collection(init.db, 'olx_categories');
        const data = await getDocs(mycollection);
        setData(data.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
        setLoading(false)
    }
    const [city, setCity] = useState()
    // console.log('city list', city)
    const getCity = async () => {
        const mycollection = collection(init.db, 'cities');
        const data = await getDocs(mycollection);
        setCity(data.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
        setLoading(false)
    }

    useEffect(() => {
        getCategories();
        getCity()
        if (!user) {
            setLoggedIn(false)
        } else {
            setLoggedIn(true)
        }
    }, [user])
    const formHandler = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setData({ ...data, [name]: value })
    }
    const btnHandler = async () => {
        const { email, password } = data;
        const auth = init.auth;
        try {
            const user = await signInWithEmailAndPassword(
                auth, email, password
            );
            // console.log(user.user.uid);
            toast.success('successfully logged in');
            handleClose()
            navigate('/');
        }
        catch (error) {
            console.log(error)
            toast.error(error.message)
            setData({
                password: '',
            })
        }
    }
    const [text, setText] = useState('')
    const [cityText, setCityText] = useState('')
    const searchHandler = (e) => {
        e.preventDefault()
        navigate(`search-result/${text}`)
    }
    const dispatch = useDispatch()
    const cityBtnHandler = (e) => {
        e.preventDefault()
        dispatch(setGlobalCity(e.target.value))
    }
    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Login to Continue</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-floating mb-3">
                        <input type="email" name="email" onChange={formHandler} className="form-control" id="email" placeholder="Enter Email" />
                        <label htmlFor="email">Email address</label>
                    </div>
                    <div className="form-floating">
                        <input type="password" name="password" onChange={formHandler} className="form-control" id="password" placeholder="Password" />
                        <label htmlFor="password">Password</label>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Link to="register" style={{ textDecoration: 'none', color: "deeporange" }}>Sign Up</Link>
                    <Button onClick={btnHandler} style={{ backgroundColor: 'darkorange', border: 'none' }}>
                        Login
                    </Button>
                </Modal.Footer>
            </Modal>
            <nav className="navbar navbar-inverse navbar-fixed-top">
                <div className='container-fluid'>
                    <div className='col-md-12 shadow-sm  '>
                        <div className='header '>
                            <div className='row'>
                                <div className='col-md-2'>
                                    <div className='logo d-flex justify-content-center'>
                                        <center>
                                            <Link to="/">
                                                <img src={Logo} style={{ height: '50px' }} />
                                            </Link></center>
                                    </div>
                                </div>
                                <div className='col-md-7 d-flex justify-content-around'>
                                    <div className="city__search">
                                        <form>
                                            {/* <div className='input'>
                                                <BsSearch className='icon' />
                                                <input type='search' placeholder='Search City...' value={cityText} onChange={e => setCityText(e.target.value)} />
                                            </div> */}
                                            <div className='input'>
                                                <BsSearch className='icon' />
                                                <select className='form-control' style={{ backgroundColor: 'transparent', border: 'none', transition: 'none', outline: 'none!important', }} onChange={cityBtnHandler}>
                                                    <option selected disabled>Select City</option>
                                                    {
                                                        city?.map((item, index) => {
                                                            return <option key={index} value={item.id}>{item.city}</option>
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </form>
                                    </div>
                                    <div className='search__product'>
                                        <form onSubmit={searchHandler}>
                                            <div className='input'>
                                                <input type='search' placeholder='Find Chair, Cameras and Tent....' value={text} onChange={e => setText(e.target.value)} />
                                                <button type="submit" className='header_btn'><BsSearchHeartFill className='search_icons' />
                                                </button>
                                            </div>
                                        </form>
                                    </div>

                                </div>
                                <div className='col-md-3'>
                                    <div className=" d-flex justify-content-around">
                                        <Link to='sell'>
                                            <button className='sell_btn '><GoPlus className='plus_icon' />SELL</button>
                                        </Link>
                                        {
                                            !loggedIn ? (
                                                <button style={{ color: 'orange', fontWeight: 'bold', backgroundColor: '#fff', border: 'none' }} onClick={handleShow}>Login</button>
                                            ) :
                                                <Link to="profile" style={{ color: 'orange', fontWeight: 'bold', backgroundColor: '#fff', textDecoration: 'none' }}>
                                                    <img src="https://e7.pngegg.com/pngimages/799/987/png-clipart-computer-icons-avatar-icon-design-avatar-heroes-computer-wallpaper-thumbnail.png" style={{ height: '50px', width: '50px', borderRadius: '50%' }} />
                                                </Link>
                                        }
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div ></nav >
            <ToastContainer />
        </>
    )
}
export default Header