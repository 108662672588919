import { collection, getDocs } from 'firebase/firestore'
import React, { useState, useEffect } from 'react'
import { useParams, useLocation, } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { AiOutlineHeart } from 'react-icons/ai'
import { ImLocation2 } from 'react-icons/im'
import { MdDateRange } from 'react-icons/md'
import ProductNameFormatter from './ProductNameFormatter'
import DateFinder from './DateFinder'
import init from './firebase'
import Img from './nopic.jpg'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import ProductNotFound from './noproduct.png'
const SearchProduct = () => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)
    console.log(data)
    const { text } = useParams()
    const getProducts = async () => {
        try {
            const mycollection = collection(init.db, 'products');
            const data = await getDocs(mycollection)
            setData(data.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
            setLoading(false)
        } catch (err) {
            console.log(err)
        }
    }
    useEffect(() => {
        getProducts()
    }, [])
    const [result, setResult] = useState()
    useEffect(() => {
        const res = data?.filter((product) => product.product_name?.toLowerCase().includes(text))
        setResult(res)
    }, [text, data])
    return (
        <>
            <div className='container'>
                <div className='row'>
                    {loading && (
                        <>
                            <div className="col-md-3">
                                <Skeleton height={180} />
                                <Skeleton height={30} />
                                <Skeleton height={30} />
                                <Skeleton height={30} />
                            </div>
                            <div className="col-md-3">
                                <Skeleton height={180} />
                                <Skeleton height={30} />
                                <Skeleton height={30} />
                                <Skeleton height={30} />
                            </div>
                            <div className="col-md-3">
                                <Skeleton height={180} />
                                <Skeleton height={30} />
                                <Skeleton height={30} />
                                <Skeleton height={30} />
                            </div>
                            <div className="col-md-3">
                                <Skeleton height={180} />
                                <Skeleton height={30} />
                                <Skeleton height={30} />
                                <Skeleton height={30} />
                            </div>
                        </>
                    )}

                    {
                        result?.map((item, index) => {
                            return (
                                <>
                                    <div className='col-md-3' key={index}>
                                        <Link to={`/product/${item.id}`} style={{ textDecoration: "none", color: "black" }}>
                                            <div className='prouduct_card'>
                                                <div className="card shadow mb-3 product__card">
                                                    <div className='card_img'>
                                                        <img src={item.productImages || Img} className="card-img-top" alt="" />
                                                        <div className='heart_icon'>
                                                            <AiOutlineHeart className='icons' />
                                                        </div>
                                                        <div className='featured'>
                                                            <p>Featured</p>
                                                        </div>
                                                    </div>

                                                    <div className="card-body">
                                                        <h5 className="card-title">
                                                            <ProductNameFormatter product_name={item.product_name} />
                                                        </h5>

                                                        <h5 className="card-title"> &#8377;
                                                            {/* {item.selling_price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} */}
                                                            {item.selling_price}

                                                        </h5>
                                                        <div className='last_row d-flex justify-content-between'>
                                                            <p className='location_row'><ImLocation2 /> Lucknow</p>
                                                            <p className='date_row'> <MdDateRange /> <DateFinder mydate={item.createdAt?.seconds} /></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>

                                </>
                            )
                        })

                    }
                    {
                        !loading && result.length < 1 ?
                            (
                                <img src={ProductNotFound} style={{ height: '150px', objectFit: 'contain' }} />
                            )
                            : null
                    }
                </div>
            </div>

        </>
    )
}

export default SearchProduct