import React, { useState, useEffect } from 'react'
import init from './firebase';
import { v4 as uuidv4 } from 'uuid';
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { useUserAuth } from './context/userAuthContext';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { doc, setDoc, collection, getDocs, addDoc, serverTimestamp } from 'firebase/firestore';

const OnRentForm = () => {
    const { user } = useUserAuth();
    const merchant_id = user?.uid;
    const navigate = useNavigate();
    const [data, setData] = useState({
        industry: '',
        city_id:'',
        product_name: '',
        condition: '',
        timeused: '',
        rent: '',
        duration: '',
        description: '',
    })

    const [previousImgUrl, setPreviousImgUrl] = useState(null);
    const [previousImgProgress, setPreviousImgProgress] = useState(0);
    const [productImages, setProductImages] = useState([])

    const formHandler = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setData({ ...data, [name]: value })
    }
    const btnHandler = async (e) => {
        e.preventDefault();
        const {
            industry,
            product_name,
            condition,
            timeused,
            city_id,
            rent,
            duration,
            description
        } = data;
        if (industry !== '' && city_id !== '' &&  product_name !== '' && condition !== '' && timeused !== '' && rent !== '' && duration !== '' && description !== '' && productImages.length > 0) {
            try {
                const docRef = await addDoc(collection(init.db, "products"), {
                    industry,
                    city_id,
                    product_name,
                    condition,
                    timeused,
                    rent,
                    duration,
                    description,
                    productImages,
                    merchant_id,
                    type: 'rent',
                    createdAt: serverTimestamp()
                });
                setData({
                    industry: '',
                    city_id:'',
                    product_name: '',
                    condition: '',
                    timeused: '',
                    rent: '',
                    duration: '',
                    description: '',
                })
                setProductImages([])
                toast.success('Product Added successfully.')

            } catch (err) {
                console.log(`Error ${err}`)
            }
        } else {
            toast.error('All field required')
        }
    }
    const previousUploader = async (e) => {
        console.log("Image process", e.target.files[0])
        const file = e.target.files[0];
        const fileExtension = file.name.split('.').pop();
        // console.log('File name ' + file + 'fileExtension ' + fileExtension)
        const storageRef = ref(init.storage, `${uuidv4()}.${fileExtension}`);
        const uploadTask = uploadBytesResumable(storageRef, file);
        uploadTask.on('state_changed',
            (snapshot) => {
                const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                //setProgresspercent(progress);
                setPreviousImgProgress(progress)
            },
            (err) => {
                console.log(err);
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    console.log('Uploaded image', downloadURL)
                    setPreviousImgUrl(downloadURL);
                    setProductImages([...productImages, downloadURL])
                });
            }
        );
    }
    const [category, setCategory] = useState()
    const getCategories = async () => {
        const mycollection = collection(init.db, 'olx_categories');
        const data = await getDocs(mycollection);
        setCategory(data.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
    }
    const [city, setCity] = useState()
    // console.log('city list', city)
    const getCity = async () => {
        const mycollection = collection(init.db, 'cities');
        const data = await getDocs(mycollection);
        setCity(data.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
    }
    useEffect(() => {
        getCategories()
        getCity()
        if (!user) {
            navigate('/')
        }
    }, [merchant_id])
    return (
        <div className='container'>

            <form onSubmit={btnHandler} >
                <div className='row  sell_new_container'>

                    <div className="col-md-6">
                        <div className='form-group'>
                            <label htmlFor="inputPassword4" className="form-label my__color">Industry<span className='text-danger'>*</span></label>
                            <select name="industry" className='form-control' value={data.industry} onChange={formHandler} >
                                <option  selected>Select Industry</option>
                                {
                                    category?.map((item, index) => {
                                        return <option value={item.id} key={index}>{item.name}</option>
                                    })
                                }
                            </select>
                        </div>
                    </div>
                    <div className='col-md-6 '>
                        <div className='form-group mb-4 '>
                            <label className="form-label my__color" htmlFor="autoSizingInputGroup">Product Name<span className='text-danger'>*</span></label>
                            <div className="input-group">
                                <input type="text" onChange={formHandler} value={data.product_name} name="product_name" className="form-control" id="autoSizingInputGroup" placeholder="Enter Product Name" />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 ">
                        <label htmlForm="Industry" className="form-label my__color">City<span className='text-danger'>*</span></label>
                        <select className='form-control' name="city_id" value={data.city_id} onChange={formHandler}>
                            <option selected >Select City</option>
                            {
                                city?.map((item, index) => {
                                    return <option key={index} value={item.id}>{item.city}</option>
                                })
                            }
                        </select>
                    </div>
                    <div className='col-md-6'>
                        <div className='form-group mb-4'>
                            <label className="form-label my__color" htmlFor="inlineFormSelectPref">Product Condition</label>
                            <select className="form-select" onChange={formHandler} value={data.condition} name="condition" id="inlineFormSelectPref">
                                <option selected >Choose Condition</option>
                                <option value="Good Condition">Good Condition</option>
                                <option value="New Condition">New Condition</option>
                                <option value="Sell Pack">Sell Pack</option>
                                <option value="Mint Condition">Mint Condition</option>
                                <option value="Repairable Condition">Repairable Condition</option>
                            </select>
                        </div>
                    </div>
                   <div className="col-md-6">
                        <div className='form-group mb-4'>
                            <label htmlFor="inputEmail4" className="form-label my__color">How Many Time it Used<span className='text-danger'>*</span></label>
                            <input type="text" onChange={formHandler} value={data.timeused} name="timeused" className="form-control" placeholder="Enter How Many Time it Used" id="inputEmail4" />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className='form-group mb-4'>
                            <label htmlFor="inputEmail4" className="form-label my__color">Price For Rent <span className='text-danger'>*</span></label>
                            <input type="number" onChange={formHandler} value={data.rent} name="rent" className="form-control" placeholder="Price For Rent" id="inputEmail4" />
                        </div>
                    </div>
                    <div className="col-md-6  ">
                        <div className='form-group mb-4'>
                            <label htmlFor="inputEmail4" className="form-label my__color">Time Duration For Rent<span className='text-danger'>*</span></label>
                            <input type="text" onChange={formHandler} value={data.duration} name="duration" className="form-control" placeholder="Time Duration" id="inputEmail4" />
                        </div>
                    </div>

                    <div className='col-md-12'>
                        <div className='form-group mb-4'>
                            <label htmlFor="exampleFormControlTextarea1" className="form-label my__color">Product Description <span className='text-danger'>*</span> </label>
                            <textarea className="form-control" id="exampleFormControlTextarea" onChange={formHandler} value={data.description} name="description" rows="4"></textarea>
                        </div>
                    </div>
                    <div className='row my-2'>
                        <div className='col-md-2 col-6'>
                            <input type="file" id="previous_input" hidden onChange={previousUploader} />
                            <label for="previous_input" className=''>
                                <div style={{ height: '100px', width: '100px', borderRadius: '10px', border: '2px solid rgba(0,0,0,0.3)', margin: '1px', display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'center', color: '#000' }}>
                                    <i className='fa fa-plus' style={{ fontSize: '22px' }}></i>
                                    Add More
                                </div>
                            </label>
                        </div>
                        {
                            productImages?.map((item, index) => {
                                return (
                                    <div className='col-md-2 col-6' key={index}>
                                        <img src={item} style={{ height: '100px', width: '100px', borderRadius: '5px', border: '2px dashed skyblue', boxShadow: '0 3px 5px rgba(0,0,0,0.2)' }} key={index} />
                                    </div>
                                )
                            })
                        }
                    </div>

                    {
                        !previousImgUrl && previousImgProgress ?
                            <div className="progress bg-white">
                                <div className="progress-bar progress-bar-striped bg-success" role="progressbar" style={{ width: `${previousImgProgress}%` }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">{previousImgProgress} %</div>
                            </div>
                            : ''
                    }
                    <div className='col-md-6  '>
                        <div className='form-group mb-4'>
                            <div className="col-auto">
                                <button type="submit" className="btn btn-primary mb-3">Submit</button>
                            </div>
                        </div>
                    </div>


                </div>
            </form>
        </div>

    )
}

export default OnRentForm