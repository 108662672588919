import React, { useState, useEffect } from 'react'
import { AiOutlineHeart } from 'react-icons/ai'
import { ImLocation2 } from 'react-icons/im'
import { MdDateRange } from 'react-icons/md'
import SubHeader from './SubHeader'
import { Link } from 'react-router-dom'
import Banner from './Banner'
import init from './firebase';
import { doc, setDoc, getDoc, collection, getDocs, query, where } from 'firebase/firestore';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import ProductNameFormatter from './ProductNameFormatter';
import DateFinder from './DateFinder'
import Img from './nopic.jpg'
import ProductNotFound from './noproduct.png'
import ProductSlider from './ProductSlider'
import CityNameFinder from './CityNameFinder'
import { useSelector } from 'react-redux'
const Home = () => {
  const redux = useSelector((state) => {
    return state.filter
})
// dispatch(setGlobalCity(id))
const global_city_id= redux?.city_id
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const [filteredSet, setFilteredSet] = useState([]);
  console.log('data',data)
  const getProduct = async () => {
    const mycollection = collection(init.db, 'products');
    // let q = query(mycollection, where('type', '==', 'old'));
    const data = await getDocs(mycollection);
    setData(data.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
    setLoading(false)
  }
  useEffect(() => {
    getProduct()
  }, [])
  useEffect(() => setFilteredSet(data), [data])
  const [productId, setProductId] = useState(null)
  useEffect(() => {
    if (!productId) return
    const applyFilter = data.filter((pre) => {
      return pre.industry === productId;
    })
    setFilteredSet(applyFilter);
  }, [productId])
  useEffect(()=>{
    if (!global_city_id) return
    const applyFilter = data.filter((pre) => {
        return pre.city_id === global_city_id;
    })
    setFilteredSet(applyFilter);

},[global_city_id])
  const greet = (name) => {
    setProductId(name)
  };
  return (
    <>
      <Banner />
      <SubHeader greet={greet} />
      <div className='container'>
        <div className='row mt-5 card__container'>
          {loading && (
            <>
              <div className="col-md-3">
                <Skeleton height={180} />
                <Skeleton height={30} />
                <Skeleton height={30} />
                <Skeleton height={30} />
              </div>
              <div className="col-md-3">
                <Skeleton height={180} />
                <Skeleton height={30} />
                <Skeleton height={30} />
                <Skeleton height={30} />
              </div>
              <div className="col-md-3">
                <Skeleton height={180} />
                <Skeleton height={30} />
                <Skeleton height={30} />
                <Skeleton height={30} />
              </div>
              <div className="col-md-3">
                <Skeleton height={180} />
                <Skeleton height={30} />
                <Skeleton height={30} />
                <Skeleton height={30} />
              </div>
            </>
          )}
          {
            filteredSet?.map((cur, index) => {
              return (
                <div className='col-md-3' key={index}>
                  <Link to={`product/${cur.id}`}>
                    <div className='prouduct_card'>
                      <div className="card shadow mb-3 product__card">
                        <div className='card_img'>
                          <img src={cur.productImages || Img} className="card-img-top" alt="" />
                          <div className='heart_icon'>
                            <AiOutlineHeart className='icons' />
                          </div>
                          <div className='featured'>
                            <p>{cur?.type}</p>
                          </div>
                        </div>
                        <div className="card-body">
                          <h5 className="card-title">
                            <ProductNameFormatter product_name={cur.product_name} />
                          </h5>
                          <h5 className="card-title"> &#8377;
                            {cur?.price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            {/* {cur.selling_price} */}
                          </h5>
                          <div className='last_row d-flex justify-content-between'>
                            <p className='location_row'><ImLocation2 /> 
                            <CityNameFinder city_id={cur.city_id}/>
                            </p>
                            <p className='date_row'> <MdDateRange /> <DateFinder mydate={cur.createdAt?.seconds} /></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              )
            })
          }
          {
            !loading && filteredSet.length < 1 ?
              (
                <img src={ProductNotFound} style={{ height: '150px', objectFit: 'contain' }} />
              )
              : null
          }
          {/* {
            category?.map(({ id, name }) => {
              return (
                <ProductSlider cat_id={id} cat_name={name} key={id} />
              )
            })
          } */}
        </div>
      </div>

    </>
  )
}

export default Home