import React, { useState, useEffect } from 'react'
import { doc, getDoc, collection, query, where } from 'firebase/firestore';
import init from './firebase';

const CityNameFinder = ({ city_id }) => {
    const [name, setName] = useState('');
    const nameFinder = async () => {
        try {
            // const res = await getDoc(doc(init.db, "cities", city_id));
            const res = await getDoc(doc(init.db, "cities", city_id))
            setName(res.data().city)
        } catch (error) {
            // console.log(`Error : ${error}`)
        }
    }
    useEffect(() => {
        if(!city_id) return
        nameFinder()
    }, [city_id])
    return (
        <span>{name}</span>
    )
}
export default CityNameFinder