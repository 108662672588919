import React, { useEffect, useState } from 'react'
import init from './firebase';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";
import { doc, setDoc, getDoc, getDocs, collection } from 'firebase/firestore';
const Register = () => {
    let navigate = useNavigate();
    const [state, setState] = useState([]);
    const [city, setCity] = useState([]);
    const [data, setData] = useState({
        name: '',
        email: '',
        mobno: '',
        state_id: '',
        city_id: '',
        password: '',
    });
    const getState = async () => {
        try {
            const mycollection = collection(init.db, 'states');
            const data = await getDocs(mycollection);
            setState(data.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
        } catch (error) {
            console.log(`Error ${error} `)
        }
    }
    const getCity = async () => {
        try {
            const mycollection = collection(init.db, 'cities');
            const data = await getDocs(mycollection);
            setCity(data.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
        } catch (error) {
            console.log(`Error ${error} `)
        }
    }
    const formHandler = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setData({ ...data, [name]: value })
    }
    const btnHandler = async (e) => {
        e.preventDefault();
        const { name, email, password, mobno, state_id, city_id } = data;
        if (name !== '' && email !== '' && password !== '' && mobno !== '' && state_id != '' && city_id != '') {
            const auth = init.auth;
            try {


                const user = await createUserWithEmailAndPassword(
                    auth, email, password
                )
                const documentId = user.user.uid;

                await setDoc(doc(init.db, "olx_user", documentId), {
                    profile: {
                        name,
                        email,
                        mobno,
                        city_id,
                        state_id
                    },
                });
                navigate('/');
            }
            catch (err) {
                toast.error(err.message);
            }
        } else {
            toast.error('All field required')
        }


    }
    useEffect(() => {
        getState()
        getCity()
    }, [])
    return (
        <div className='container'>
            <div className='row py-3'>
                <div className='card'>
                    <div className='card-header text-center text-white bg-warning'>
                        Create Your Profile
                    </div>
                    <div className='card-body'>
                        <div className='col-md-12'>
                            <form onSubmit={btnHandler}>
                                <div className='row mt-3'>

                                    <div className='form-group col-md-6'>
                                        <input type="text" name="name" onChange={formHandler} value={data.name} placeholder='Enter Name' className='form-control' />
                                    </div>
                                    <div className='form-group col-md-6'>
                                        <input type="tel" name="mobno" onChange={formHandler} value={data.mobno} minLength={10} maxLength={10} placeholder='Enter Mobile Number' className='form-control' />
                                    </div>
                                </div>
                                <div className='row mt-3'>
                                    <div className='form-group col-md-6'>
                                        <input type="email" name="email" onChange={formHandler} value={data.email} placeholder='Enter Email' className='form-control' />
                                    </div>
                                    <div className='form-group col-md-6'>
                                        <select className="form-control" name="state_id" onChange={formHandler} >
                                            <option>Select State</option>
                                            {
                                                state.map((item, index) => {
                                                    return (
                                                        <option value={item.id} key={index} selected={(item.id === data.state_id)}>{item.name}</option>
                                                    )
                                                })
                                            }
                                        </select>

                                    </div>
                                    <div className='form-group col-md-6 mt-3'>
                                        <select className={`form-control`} name="city_id" onChange={formHandler}>
                                            <option>Select City</option>
                                            {
                                                city.filter((pre) => pre.state_id === data.state_id).map((item, index) => {
                                                    return (
                                                        <option value={item.id} selected={(item.id === data.city_id)}>{item.city}</option>
                                                    )
                                                })
                                            }
                                        </select>


                                    </div>
                                    <div className='form-group col-md-6 mt-3'>
                                        <input type="password" name="password" onChange={formHandler} value={data.password} placeholder='Create Password' className='form-control' autoComplete='off' />
                                    </div>
                                </div>
                                <div className='form-group mt-3'>
                                    <input type="submit" value="Register Now" className='btn btn-warning' />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Register