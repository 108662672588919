import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import init from './firebase';
import { getDoc, doc } from 'firebase/firestore';
import EditNewForm from './EditNewForm';
import EditOldForm from './EditOldForm';
import EditOnRentForm from './EditOnRentForm';
const EditProduct = () => {
    const { product_id } = useParams();
    const [data, setData] = useState([])
    console.log(data)
    const getSingleProduct = async () => {
        try {
            const res = await getDoc(doc(init.db, "products", product_id));
            setData(res.data());
        } catch (error) {
            console.log(`Error : ${error}`)
        }
    }
    useEffect(() => {
        getSingleProduct()
    }, [product_id])
    switch (data?.type) {
        case 'new': return <EditNewForm newData={data} product_id={product_id} />
        case 'old': return <EditOldForm newData={data} product_id={product_id} />
        case 'rent': return <EditOnRentForm newData={data} product_id={product_id} />
        default: return <h1>Not matched</h1>
    }
}

export default EditProduct