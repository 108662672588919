import { collection, where, getDocs, query } from 'firebase/firestore'
import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import init from './firebase'
import { AiOutlineHeart } from 'react-icons/ai';
import { ImLocation2 } from 'react-icons/im';
import { MdDateRange } from 'react-icons/md';
import DateFinder from './DateFinder';
import CityNameFinder from './CityNameFinder';
const AllProducts = () => {
    const [product, setProduct] = useState([])
    console.log(product)
    const { merchant_id } = useParams()
    const getProducts = async () => {
        const mycollection = collection(init.db, 'products');
        let q = query(mycollection, where('merchant_id', '==', merchant_id));
        const data = await getDocs(q);
        setProduct(data.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
    }
console.log('products ' , product)
    useEffect(() => {
        getProducts()
    }, [merchant_id])
    return (
        <div className='container'>
            <div className='row'>
                <div className='row card__container'>
                    {product?.map((cur, index) => {
                        return (
                            <div className='col-md-3' key={index}>
                                <Link to={`/product/${cur?.id}`}>
                                    <div className='prouduct_card'>
                                        <div className="card shadow mb-3">
                                            <div className='card_img'>
                                                <img src={cur?.productImages} className="card-img-top" alt="..." />
                                                <div className='heart_icon'>
                                                    <AiOutlineHeart className='icons' />
                                                </div>
                                                <div className='featured'>
                                                    <p>Featured</p>
                                                </div>
                                            </div>

                                            <div className="card-body">
                                                <h5 className="card-title">{`${cur.product_name?.length > 25 ? cur?.product_name?.slice(0, 15) : cur?.product_name}...`}</h5>

                                                <h5 className="card-title"> &#8377;
                                                    {cur?.selling_price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                    {cur?.price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                </h5>
                                                <div className='last_row d-flex justify-content-between'>
                                                    <p className='location_row'><ImLocation2 />
                                                     <CityNameFinder city_id={cur?.city_id}/>
                                                     </p>
                                                    <p className='date_row'> <MdDateRange />

                                                        <DateFinder mydate={cur.createdAt?.seconds} />
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default AllProducts