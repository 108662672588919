import React, { useState, useEffect } from 'react'
import { doc, setDoc, collection, getDocs, getDoc, serverTimestamp } from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid';
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import './Sell.css'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import init from './firebase';
import { useUserAuth } from './context/userAuthContext';

const EditNewForm = ({ newData, product_id }) => {
    const { user } = useUserAuth();
    const merchant_id = user?.uid;
    const [category, setCategory] = useState();
    const [data, setData] = useState({
        industry: '',
        city_id:'',
        product_name: '',
        quantity: '',
        size: '',
        price: '',
        discount_price: '',
        delivery: '',
        negotiable: '',
        description: ''
    })
    console.log(data)

    const getSingleProduct = async () => {
        const docRef = doc(init.db, "products", product_id);
        const data = await getDoc(docRef);
        setData(data.data())
        setProductImages(data.data().productImages)
    }
    const formHandler = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setData({ ...data, [name]: value })
    }
    const [previousImgUrl, setPreviousImgUrl] = useState(null);
    const [previousImgProgress, setPreviousImgProgress] = useState(0);
    const [productImages, setProductImages] = useState([])

    const btnHandler = async (e) => {
        e.preventDefault();

        const {
            industry,
            city_id,
            product_name,
            quantity,
            size,
            price,
            discount_price,
            delivery,
            negotiable,
            description
        } = data;
        if (industry !== '' && city_id !== '' &&  product_name !== '' && quantity !== '' && size !== '' && price !== '' && discount_price !== '' && delivery !== '' && negotiable != '' && description !== '') {
            try {
                const docRef = await setDoc(doc(init.db, "products", product_id), {
                    industry,
                    city_id,
                    product_name,
                    quantity,
                    size,
                    price,
                    discount_price,
                    delivery,
                    negotiable,
                    productImages,
                    merchant_id,
                    description,
                    type: 'new',
                });
                setData({
                    industry: '',
                    city_id:'',
                    product_name: '',
                    quantity: '',
                    size: '',
                    price: '',
                    discount_price: '',
                    delivery: '',
                    negotiable: ''
                })
                setProductImages([])
                toast.success('Product Updated successfully.')

            } catch (err) {
                console.log(`Error ${err}`)
            }
        } else {
            toast.error('All field required.')
        }


    }

    const getCategories = async () => {
        const mycollection = collection(init.db, 'olx_categories');
        const data = await getDocs(mycollection);
        setCategory(data.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
    }
    const [city, setCity] = useState()
    // console.log('city list', city)
    const getCity = async () => {
        const mycollection = collection(init.db, 'cities');
        const data = await getDocs(mycollection);
        setCity(data.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
    }
    useEffect(() => {
        getSingleProduct()
        getCategories()
        getCity()
    }, [product_id])
    const previousUploader = async (e) => {
        console.log("Image process", e.target.files[0])
        const file = e.target.files[0];
        const fileExtension = file.name.split('.').pop();
        // console.log('File name ' + file + 'fileExtension ' + fileExtension)
        const storageRef = ref(init.storage, `${uuidv4()}.${fileExtension}`);
        const uploadTask = uploadBytesResumable(storageRef, file);
        uploadTask.on('state_changed',
            (snapshot) => {
                const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                //setProgresspercent(progress);
                setPreviousImgProgress(progress)
            },
            (err) => {
                console.log(err);
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    console.log('Uploaded image', downloadURL)
                    setPreviousImgUrl(downloadURL);
                    setProductImages([...productImages, downloadURL])
                });
            }
        );
    }
    return (
        <div className='container'>
            <form onSubmit={btnHandler}>
                <div className='row mb-2 mt-2 sell__new__container'>

                    <div className="col-md-6 mb-2 mt-2">
                        <label htmlForm="Industry" className="form-label my__color">Industry<span className='text-danger'>*</span></label>
                        <select className='form-control' name="industry" onChange={formHandler}>
                            <option disabled selected>Select Category </option>
                            {
                                category?.map((item, index) => {
                                    return (
                                        <option value={item.id} key={index} selected={`${item.id === data.industry ? 'selected' : ''}`}> {item.name}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                    <div className="col-md-6 mb-2 mt-2">
                        <label htmlForm="Industry" className="form-label my__color">City<span className='text-danger'>*</span></label>
                        <select className='form-control' name="city_id" onChange={formHandler}>
                            <option selected disabled>Select City</option>
                            {
                                city?.map((item, index) => {
                                    return <option key={index} value={item.id} selected={`${item.id === data.city_id ? 'selected' : ''}`}>{item.city}</option>
                                })
                            }
                        </select>
                    </div>
                    <div className='col-md-6 mb-2 mt-2 mb-3 mt-3mb-2 mt-2'>
                        <label className="form-label my__color" htmlForm="autoSizingInputGroup">Product Name</label>
                        <input type="text" className="form-control" placeholder="Product Name" id="Industry" name="product_name" value={data.product_name} onChange={formHandler} />
                    </div>
                    <div className="col-md-6 mb-2 mt-2">
                        <label htmlForm="inputEmail4" className="form-label my__color">Quantity Available<span className='text-danger'>*</span></label>
                        <input type="Number" className="form-control" placeholder="Enter Quantity" id="inputEmail4" name="quantity" value={data.quantity} onChange={formHandler} />
                    </div>
                    <div className="col-md-6 mb-2 mt-2">
                        <label htmlForm="Size" className="form-label my__color">Product Size<span className='text-danger'>*</span></label>
                        <input type="text" className="form-control" placeholder="Enter Size" id="Size" name="size" value={data.size} onChange={formHandler} />
                    </div>
                    <div className="col-md-6 mb-2 mt-2">
                        <label htmlForm="Price" className="form-label my__color">Product Price<span className='text-danger'>*</span></label>
                        <input type="Number" className="form-control" placeholder="Product Price" id="Price" name="price" value={data.price} onChange={formHandler} />
                    </div>
                    <div className="col-md-6 mb-2 mt-2">
                        <label htmlForm="DiscountPrice" className="form-label my__color">Discount Price<span className='text-danger'>*</span></label>
                        <input type="Number" className="form-control" placeholder="Discount Price" id="DiscountPrice" name="discount_price" value={data.discount_price} onChange={formHandler} />
                    </div>
                    <div className='col-md-6 mb-2 mt-2'>
                        <label className="form-label my__color" htmlForm="inlineFormSelectPref">Delivery All</label>
                        <select className="form-select" id="inlineFormSelectPref" name="delivery" onChange={formHandler}>
                            <option value="All India" selected={`${data.delivery === 'All India' ? 'selected' : ''}`}> All India</option>
                            <option value="On Shop" selected={`${data.delivery === 'On Shop' ? 'selected' : ''}`}>On Shop</option>
                            <option value="Local City Only" selected={`${data.delivery === 'Local City Only' ? 'selected' : ''}`}>Local City Only</option>
                        </select>
                    </div>
                    <div className='col-md-6 mb-2 mt-2'>
                        <label className="form-label my__color" htmlForm="inlineFormSelectPref">Negotiable</label>
                        <select className="form-select" id="inlineFormSelectPref" name="negotiable" onChange={formHandler}>
                            <option value="Yes" selected={`${data?.negotiable === 'Yes' ? 'selected' : ''}`}>Yes</option>
                            <option value="No" selected={`${data?.negotiable === 'No' ? 'selected' : ''}`}>No</option>
                        </select>
                    </div>

                    <div className='col-md-12'>
                        <label htmlForm="exampleFormControlTextarea1" className="form-label my__color">Product Details <span className='text-danger'>*</span> </label>
                        <textarea className="form-control" id="exampleFormControlTextarea1" rows="4" placeholder='Product details' name="description" onChange={formHandler} value={data?.description}></textarea>
                    </div>
                    <div className='row my-2'>
                        <div className='col-md-2 col-6'>
                            <input type="file" id="previous_input" hidden onChange={previousUploader} />
                            <label for="previous_input" className=''>
                                <div style={{ height: '100px', width: '100px', borderRadius: '10px', border: '2px solid rgba(0,0,0,0.3)', margin: '1px', display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'center', color: '#000' }}>
                                    <i className='fa fa-plus' style={{ fontSize: '22px' }}></i>
                                    Add More
                                </div>
                            </label>
                        </div>
                        {
                            productImages?.map((item, index) => {
                                return (
                                    <div className='col-md-2 col-6' key={index}>
                                        <img src={item} style={{ height: '100px', width: '100px', borderRadius: '5px', border: '2px dashed skyblue', boxShadow: '0 3px 5px rgba(0,0,0,0.2)' }} key={index} />
                                    </div>
                                )
                            })
                        }
                    </div>

                    {
                        !previousImgUrl && previousImgProgress ?
                            <div className="progress bg-white">
                                <div className="progress-bar progress-bar-striped bg-success" role="progressbar" style={{ width: `${previousImgProgress}%` }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">{previousImgProgress} %</div>
                            </div>
                            : ''
                    }

                    <div className='col-md-6 mb-2 mt-2'>
                        <div className="col-auto">
                            <button type="submit" className="btn btn-primary mb-3">Update</button>
                        </div>
                    </div>

                </div>
            </form >
            <ToastContainer />
        </div >
    )
}

export default EditNewForm