import React from 'react'
import { useNavigate } from "react-router-dom";
import { useUserAuth } from './context/userAuthContext';
const ProtectedRoute = ({ children }) => {
    const { user } = useUserAuth();
    const navigate = useNavigate();
    if (!user) {
        navigate('/')
    }
    return children;
};
export default ProtectedRoute