import React, { useState, useEffect } from 'react'
import init from './firebase'
import { useUserAuth } from './context/userAuthContext'
import { doc, getDoc, getDocs, collection, query, where } from 'firebase/firestore';
import MerchantNameFinder from './MerchantNameFinder';
import DateFinder from './DateFinder';
import ProductNameFinder from './ProductNameFinder';
import SellerNumberFinder from './SellerNumberFinder';
const Notification = () => {
    const { user } = useUserAuth();
    const merchant_id = user.uid;
    const [notification, setNotification] = useState([]);
    console.log(notification)
    const getNotification = async () => {
        const mycollection = collection(init.db, 'olx_requests');
        let q = query(mycollection, where('seller_id', '==', merchant_id));
        const data = await getDocs(q);
        setNotification(data.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
    }
    useEffect(() => {
        getNotification()
    }, [merchant_id])
    return (
        <div className='container'>
            <div className='row'>
                <div className='col-md-12'>
                    <table className='table table-bordered'>
                        <tbody>
                            <tr>
                                <th>S.No.</th>
                                <th>Buyer Name</th>
                                <th>Product Name</th>
                                <th>Contact Number</th>
                                <th>Requested Date</th>
                            </tr>
                            {
                                notification?.map((item, index) => {
                                    return (
                                        <tr>
                                            <td>{index + 1}</td>
                                            <td><MerchantNameFinder user_id={item.buyer_id} /></td>
                                            <td style={{ width: "40%" }}><ProductNameFinder product_id={item.product_id} /></td>
                                            <td><SellerNumberFinder mobno={item.mobno} /></td>
                                            <td><DateFinder mydate={item.createdAt} /></td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default Notification