const MyData = [
    {
        image: 'https://images.unsplash.com/photo-1505740420928-5e560c06d30e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8cHJvZHVjdCUyMGltYWdlc3xlbnwwfHwwfHw%3D&w=1000&q=80',
        product_name: ' old Bhgona  ',
        Price: '100',
        location: 'Gomati Nagar, Lucknow',
        Date: 'Tomorrow'
    },
    {
        image: 'https://images.unsplash.com/photo-1505740420928-5e560c06d30e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8cHJvZHVjdCUyMGltYWdlc3xlbnwwfHwwfHw%3D&w=1000&q=80',
        product_name: 'Tent',
        Price: '200',
        location: 'Gomati Nagar, Lucknow',
        Date: '05 April'
    },
    {
        image: 'https://images.unsplash.com/photo-1505740420928-5e560c06d30e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8cHJvZHVjdCUyMGltYWdlc3xlbnwwfHwwfHw%3D&w=1000&q=80',
        product_name: 'Tent',
        Price: '300',
        location: 'Gomati Nagar, Lucknow',
        Date: '10 September'
    },
    {
        image: 'https://images.unsplash.com/photo-1505740420928-5e560c06d30e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8cHJvZHVjdCUyMGltYWdlc3xlbnwwfHwwfHw%3D&w=1000&q=80',
        product_name: 'Tent',
        Price: '3343',
        location: 'Gomati Nagar, Lucknow',
        Date: 'Today'
    },
]
export default MyData