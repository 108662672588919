import React, { useState, useEffect } from 'react'
import init from './firebase'
import { doc, getDoc, collection, query, where } from 'firebase/firestore';
const MerchantNameFinder = ({ user_id }) => {
    const [name, setName] = useState('');
    const merchantFinder = async () => {
        try {
            const res = await getDoc(doc(init.db, "olx_user", user_id));
            console.log(res.data())
            setName(res?.data()?.profile.name)
        } catch (error) {
            console.log(`Error : ${error}`)
        }
    }
    useEffect(() => {
        merchantFinder()
    }, [user_id])
    return (
        <span>{name}</span>
    )
}

export default MerchantNameFinder