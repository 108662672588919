import React, { useState, useEffect } from 'react'
import init from './firebase'
import { collection, getDocs } from 'firebase/firestore'
import SubHeader from './SubHeader'
const LatestJob = () => {
    const [data, setData] = useState()
    console.log(data)
    const getJobProfile = async () => {
        const mycollection = collection(init.db, 'olx_staff');
        const data = await getDocs(mycollection);
        setData(data.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
    }
    useEffect(() => {
        getJobProfile()
    }, [])
    const btnHandler = (post_id) => {
        alert(post_id)
    }
    return (
        <>
            {/* <SubHeader /> */}
            <div className='container'>
                <div className='row'>
                    {
                        data?.map((item, index) => {
                            return (
                                <div className='col-md-4'>
                                    <div className='card my-2 shadow-sm'>
                                        <div className='card-header bg-white'>
                                            <h5>{item.title}</h5>
                                        </div>
                                        <div className='card-body'>
                                            Experience : {item.experience} year<br />
                                            Salary : {item.salaryfrom} - {item.salaryto}<br />
                                            Qualification: {item.qualification}<br />
                                            <p>{item.description}</p>
                                        </div>
                                        <div className='card-footer'>
                                            <button className='btn btn-warning btn-desk' onClick={() => btnHandler(item.id)}>Apply Now</button>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div >
        </>

    )
}

export default LatestJob