import React from 'react'

const ProductNameFormatter = ({ product_name }) => {
    const p_name = product_name?.charAt(0).toUpperCase() + product_name?.slice(1);

    return (
        <>
            {`${p_name.length > 25 ? p_name.slice(0, 15) + '...' : p_name}`}
        </>
    )
}

export default ProductNameFormatter