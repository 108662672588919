import React, { useState, useEffect } from 'react'
import MyData from './MyData';
import init from './firebase';
import { collection, getDocs } from 'firebase/firestore';
const SellForm = () => {
    const [data, setData] = useState(MyData)
    const [form, setForm] = useState({
        title: '',
        description: '',
        price: '',
    });
    const formHandler = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setForm({ ...form, [name]: value })
    }
    const [category, setCategory] = useState()
    const getCategories = async () => {
        const mycollection = collection(init.db, 'olx_categories');
        const data = await getDocs(mycollection);
        setData(data.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
    }
    useEffect(() => {
        getCategories();
    }, [])

    const SubmitHandler = () => {
        console.log(data)
        //process with backend

    }
    return (
        <>
            <div className='container'>
                <form class="row g-3">
                    <div className='row mt-2'>
                        <div className='col-md-6 mt-3'>
                            <label for="validationServer01" class="form-label" style={{ fontWeight: '600', fontSize: '18px' }}>Select Category</label>
                            <select class="form-select" aria-label="Default select example">
                                <option>Select Category</option>
                                {
                                    data.map((cur, index) => {
                                        return (
                                            <option key={index}
                                                onChange={
                                                    (e) => setCategory(cur.name)
                                                }
                                            >{cur.name}
                                            </option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <div className='col-md-6 mt-3'>
                            <div class="mb-3">
                                <label for="formGroupExampleInput" class="form-label" style={{ fontWeight: '600', fontSize: '18px' }} >Ad Title<span className='text-danger'>*</span></label>
                                <input type="text" class="form-control" id="formGroupExampleInput" placeholder="Ad Title" />
                                <div class="description" name='title' onChange={formHandler} value={form.title}>
                                    Mention the key Feature of your item(eq.Brand,Model,age,type)
                                </div>
                            </div>

                        </div>
                        <div className='col-md-6'>
                            <label for="validationServer01" class="form-label" style={{ fontWeight: '600', fontSize: '18px' }}>Description<span className='text-danger'>*</span></label>
                            <div class="form-floating">
                                <textarea class="form-control" placeholder="Leave a comment here" id="floatingTextarea" style={{ height: '100px' }} onChange={formHandler} value={form.description} name='description'></textarea>
                                <label for="floatingTextarea">Comments</label>
                                <div class="description" style={{ fontWeight: '550', fontSize: '14px' }}>
                                    Include condition,features and versions for selling
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6 mt-1'>

                            <div class="col-auto">
                                <label for="validationServer01" class="form-label" style={{ fontWeight: '600', fontSize: '18px' }}>Set A Price<span className='text-danger'>*</span></label>
                                <div class="input-group">

                                    <div class="input-group-text">&#8377;</div>
                                    <input type="number" class="form-control" id="autoSizingInputGroup" placeholder="Price" onChange={formHandler} value={form.price} name='price' />
                                </div>
                            </div>
                        </div>
                        {/* <div className='col-md-6 mt-3'>
                            <div class="mb-3">
                                <label for="formGroupExampleInput" class="form-label" style={{fontWeight:'600', fontSize:'18px'}}> Year is mandatory<span className='text-danger'>*</span></label>
                                <input type="text" class="form-control" id="formGroupExampleInput" placeholder="Ad Title" />
                            </div>
                        </div> */}

                        <div class="mt-3 mb-3">

                        </div>
                        <div className='col-md-6 mt-3 mb-3'>
                            <button type="submit" class="btn btn-primary" onClick={SubmitHandler}>Post Now</button>
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}

export default SellForm