import React, { useState, useEffect } from 'react'
import init from './firebase'
import { doc, getDoc, collection, query, where } from 'firebase/firestore';
const IndustrytNameFinder = ({ industry_id }) => {
    const [name, setName] = useState('');

    const nameFinder = async () => {
        try {
            const res = await getDoc(doc(init.db, "olx_categories", industry_id));
            setName(res?.data()?.name)
        } catch (error) {
            console.log(`Error : ${error}`)
        }
    }
    useEffect(() => {
        nameFinder()
    }, [industry_id])
    return (
        <span>{name}</span>
    )
}

export default IndustrytNameFinder