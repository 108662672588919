import React from 'react'
import Home from './Home'
import ProductPreview from './ProductPreview'
import { Routes, Route } from 'react-router-dom'
import Header from './Header'
import Footer from './Footer'
import { UserAuthContextProvider } from './context/userAuthContext'
import Logout from './Logout'
import Profile from './Profile'
import Register from './Register'
import SellForm from './SellForm'
import Sell from './Sell'
import SellNew from './SellNew'
import SellOld from './SellOld'
import JobSeeker from './JobSeeker'
import Staff from './Staff'
import { ImNotification } from 'react-icons/im'
import Notification from './Notification'
import ProtectedRoute from './ProtectedRoute'
import LatestJob from './LatestJob'
import BuyNew from './BuyNew'
import BuyNewPreview from './BuyNewPreview'
import OnRentForm from './OnRentForm'
import OnRent from './OnRent'
import BuyOld from './BuyOld'
import OnRentPreview from './OnRentPreview'
import Contact from './Contact'
import AllProducts from './AllProducts'
import EditProduct from './EditProduct'
import SearchProduct from './SearchProduct'
const App = () => {

  return (
    <>
      <UserAuthContextProvider>
        <Header />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='sellform' element={<SellForm />} />
          <Route path='/buy-old/' element={<BuyOld />} />
          <Route path='buy-new/' element={<BuyNew />} />
          <Route path='buy-new/:cat_id/:id' element={<BuyNewPreview />} />
          <Route path="register" element={<Register />} />
          <Route path="product/:id" element={<ProductPreview />} />
          <Route path="logout" element={<Logout />} />
          <Route path="profile" element={<Profile />} />
          <Route path="sell" element={<Sell />} />
          <Route path="sell-new" element={<ProtectedRoute><SellNew /></ProtectedRoute>} />
          <Route path="sell-old" element={<ProtectedRoute><SellOld /></ProtectedRoute>} />
          <Route path="job-seeker" element={<JobSeeker />} />
          <Route path="staff" element={<Staff />} />
          <Route path="notification" element={<Notification />} />
          <Route path="latest-job" element={<LatestJob />} />
          <Route path="on-rent-form" element={<OnRentForm />} />
          <Route path="on-rent" element={<OnRent />} />
          <Route path="on-rent/:id" element={<OnRentPreview />} />
          <Route path='contact-us' element={<Contact />} />
          <Route path='products/:merchant_id' element={<AllProducts />} />
          <Route path='edit-product/:product_id' element={<EditProduct />} />
          <Route path='search-result/:text' element={<SearchProduct />} />
        </Routes>
        <Footer />
      </UserAuthContextProvider>

    </>
  )
}
export default App