import React, { useState, useEffect } from 'react'
import './Sell.css'
import { collection, getDocs } from 'firebase/firestore';
import init from './firebase';
const JobSeeker = () => {
    const [data, setData] = useState({
        industry: '',
        title: '',
        jobtype: '',
        salaryfrom: '',
        salaryto: '',
        experience: '',
        qualification: '',
        description: ''

    });
    const formHandler = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setData({ ...data, [name]: value })

    }
    const SubmitHandler = () => {
        console.log(data);
        //process with backend

    }
    const [category, setCategory] = useState()
    const getCategories = async () => {
        const mycollection = collection(init.db, 'olx_categories');
        const data = await getDocs(mycollection);
        setCategory(data.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
    }
    useEffect(() => {
        getCategories()
    }, [])
    const previousUploader = () => {
        alert('ok')
    }
    return (
        <div className='container'>
            <form onSubmit={SubmitHandler}>
                <div className='row mb-2 mt-2 sell__new__container'>
                    <div className="col-md-6">
                        <div className='form-group mb-3'>
                            <label className="form-label my__color">Industry<span className='text-danger'>*</span></label>
                            <select className='form-control' onChange={formHandler} value={data.industry} name='industry'>
                                <option value="Event Industry">Event Industry</option>
                                <option value="Catering Industry">Catering Industry</option>
                                {
                                    category?.map((item, index) => {
                                        return <option key={index}>{item.name}</option>
                                    })
                                }
                            </select>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className='form-group mb-3'>
                            <label htmlFor="inputPassword4" className="form-label my__color">Apply For<span className='text-danger'>*</span></label>
                            <input type="text" className="form-control" onChange={formHandler} value={data.title} name='title' placeholder="Job Title" id="inputnumber4" />
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='form-group mb-3'>
                            <label className="form-label my__color" htmlFor="inlineFormSelectPref">Job Type</label>
                            <select className="form-select" onChange={formHandler} value={data.jobtype} name='jobtype' id="inlineFormSelectPref">
                                <option selected>Select Job Type</option>
                                <option value="1">Full Time</option>
                                <option value="2">Part Time</option>
                                <option value="3">Freelancer</option>
                                <option value="3">Per Day </option>
                            </select>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className='form-group mb-3'>
                            <label className='form-label my__color'>Expected Salary <span className='text-muted'>(optional)</span></label>
                            <input type="number" className="form-control" placeholder="Expected Salary" onChange={formHandler} value={data.salaryfrom} name='salaryfrom' id="inputEmail4" />
                        </div>
                    </div>

                    <div className="col-md-6 ">
                        <div className='form-group mb-3'>
                            <label htmlFor="inputEmail4" className="form-label my__color">Work Experience<span className='text-muted'>(optional)</span></label>
                            <input type="number" className="form-control" placeholder="Work Experience" id="inputEmail4" onChange={formHandler} value={data.experience} name='experience' />
                        </div>
                    </div>
                    <div className="col-md-6 ">
                        <div className='form-group mb-3'>
                            <label htmlFor="inputEmail4" className="form-label my__color">Prefered City<span className='text-danger'>*</span></label>
                            <input type="text" className="form-control" placeholder="Prefered City" id="inputEmail4" onChange={formHandler} value={data.experience} name='experience' />
                        </div>
                    </div>
                    <div className="col-md-6 ">
                        <div className='form-group mb-3'>
                            <label htmlFor="inputEmail4" className="form-label my__color">Last Job Position<span className='text-muted'>(optional)</span></label>
                            <input type="text" className="form-control" placeholder="Last Job Position" id="inputEmail4" onChange={formHandler} value={data.experience} name='experience' />
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='form-group mb-3'>
                            <label className="form-label my__color" htmlFor="inlineFormSelectPref">Qualification</label>
                            <select className="form-select" id="inlineFormSelectPref" onChange={formHandler} value={data.qualification} name='qualification'>
                                <option selected>Select Your Qualification </option>
                                <option value="Primary">Primary</option>
                                <option value="High School">High School</option>
                                <option value="Intermediate">Intermediate</option>
                                <option value="ITI">ITI</option>
                                <option value="Diploma">Diploma </option>
                                <option value="BTech">Graduate </option>
                                <option value="Mtech">Post Graduate </option>
                                <option value="Other">Other </option>
                            </select>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='form-group mb-3'>
                            <label className='my__color form-label'>Upload Image <span className='text-danger'>*</span></label>
                            <input type="file" name="photo" className='form-control' />
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='form-group mb-3'>
                            <label className='my__color form-label'>Upload CV/Resume <span className='text-muted'>(optional)</span></label>
                            <input type="file" name="photo" className='form-control' />
                        </div>
                    </div>

                    <div className='form-group mb-3'>
                        <label className="form-label my__color">About Yourself <span className='text-danger'>*</span> </label>

                        <textarea className="form-control" rows="4" onChange={formHandler} value={data.description} name='description'></textarea>
                    </div>
                    <div className='form-group'>
                        <div className='col-md-2 col-6'>

                            <input type="file" id="previous_input" hidden onChange={previousUploader} />
                            <label for="previous_input" className=''>
                                <div style={{ height: '100px', width: '100px', borderRadius: '10px', border: '2px solid rgba(0,0,0,0.3)', margin: '1px', display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'center', color: '#000' }}>
                                    <i className='fa fa-plus' style={{ fontSize: '22px' }}></i>
                                    Add More
                                </div>
                            </label>
                        </div>
                    </div>
                    <div className='form-group'>
                        <button type="submit" className="btn btn-primary mb-3">Submit</button>
                    </div>
                </div>
            </form>

        </div>

    )
}

export default JobSeeker