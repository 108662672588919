import { createSlice } from '@reduxjs/toolkit'
const filterSlice = createSlice({
    name: 'filter',
    initialState: {
        city_id: '',
    },
    reducers: {
        setGlobalCity(state, action) {
            return {
                city_id: action.payload
            }
        }
    },
})

export default filterSlice.reducer
export const { setGlobalCity } = filterSlice.actions;