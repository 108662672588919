import React, { useState, useEffect } from 'react'
import './Sell.css'
import { collection, getDocs, setDoc, doc, serverTimestamp } from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid';
import init from './firebase';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useUserAuth } from './context/userAuthContext';
const Staff = () => {
    const { user } = useUserAuth();
    const merchant_id = user?.uid;
    const navigate = useNavigate();
    const [data, setData] = useState({
        industry: '',
        city_id:'',
        title: '',
        jobtype: '',
        salaryfrom: '',
        salaryto: '',
        experience: '',
        qualification: '',
        description: ''
    });
    const formHandler = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setData({ ...data, [name]: value })

    }
    const SubmitHandler = async (e) => {
        e.preventDefault();
        const {
            industry,
            title,
            city_id,
            jobtype,
            salaryfrom,
            salaryto,
            experience,
            qualification,
            description
        } = data;
        if (industry !== '' && city_id !== '' &&  title !== '' && jobtype !== '' && salaryfrom !== '' && salaryto !== '' && experience !== '' && qualification !== '' && description !== '') {
            try {
                const docRef = await setDoc(doc(init.db, "olx_staff", uuidv4()), {
                    industry,
                    title,
                    city_id,
                    jobtype,
                    salaryfrom,
                    salaryto,
                    experience,
                    qualification,
                    description,
                    merchant_id,
                    createdAt: serverTimestamp()
                });
                toast.success('Post Added successfully.')
                setData({
                    industry: '',
                    city_id:'',
                    title: '',
                    jobtype: '',
                    salaryfrom: '',
                    salaryto: '',
                    experience: '',
                    qualification: '',
                    description: '',
                })
            } catch (err) {
                console.log(`Error ${err}`)
            }
        } else {
            toast.error('All field required')
        }


    }
    const [category, setCategory] = useState()
    const getCategories = async () => {
        const mycollection = collection(init.db, 'olx_categories');
        const data = await getDocs(mycollection);
        setCategory(data.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
    }
    const [city, setCity] = useState()
    // console.log('city list', city)
    const getCity = async () => {
        const mycollection = collection(init.db, 'cities');
        const data = await getDocs(mycollection);
        setCity(data.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
    }
    useEffect(() => {
        getCategories()
        getCity()
        if (!user) {
            navigate('/')
        }
    }, [merchant_id])
    return (
        <div className='container'>
            <form onSubmit={SubmitHandler}>
                <div className='row mb-2 mt-2 sell__new__container'>
                    <div className="col-md-6 mb-2 mt-2">
                        <label className="form-label my__color">Industry<span className='text-danger'>*</span></label>
                        <select className='form-control' onChange={formHandler} value={data.industry} name='industry'>
                            {
                                category?.map((item, index) => {
                                    return <option value={item.id} key={index}>{item.name}</option>
                                })
                            }
                        </select>
                    </div>
                    <div className="col-md-6 mb-2 mt-2">
                        <label htmlForm="Industry" className="form-label my__color">City<span className='text-danger'>*</span></label>
                        <select className='form-control' name="city_id" onChange={formHandler}>
                            <option selected disabled>Select City</option>
                            {
                                city?.map((item, index) => {
                                    return <option key={index} value={item.id}>{item.city}</option>
                                })
                            }
                        </select>
                    </div>
                    <div className="col-md-6 mb-2 mt-2">
                        <label htmlFor="inputPassword4" className="form-label my__color">Job Title<span className='text-danger'>*</span></label>
                        <input type="text" className="form-control" onChange={formHandler} value={data.title} name='title' placeholder="Job Title" id="inputnumber4" />
                    </div>
                    <div className='col-md-6 mb-2 mt-2'>
                        <label className="form-label my__color" htmlFor="inlineFormSelectPref">Job Type</label>
                        <select className="form-select" onChange={formHandler} value={data.jobtype} name='jobtype' id="inlineFormSelectPref">
                            <option selected>Select Job Type</option>
                            <option value="1">Full Time</option>
                            <option value="2">Part Time</option>
                            <option value="3">Freelancer</option>
                            <option value="3">Per Day </option>
                        </select>
                    </div>
                    <div className="col-md-6 d-flex ">
                        <div className="col-md-6 mb-2  pl-4 p-2">
                            <label htmlFor="inputEmail4" className="form-label my__color">Salary From <span className='text-danger'>*</span></label>
                            <input type="number" className="form-control" placeholder="Salary From " onChange={formHandler} value={data.salaryfrom} name='salaryfrom' id="inputEmail4" />
                        </div>
                        <div className="col-md-6 mb-2  p-2">
                            <label htmlFor="inputEmail4" className="form-label my__color">Salary To<span className='text-danger'>*</span></label>
                            <input type="number" className="form-control" placeholder="Salary To " id="inputEmail4" onChange={formHandler} value={data.salaryto} name='salaryto' />
                        </div>
                    </div>

                    <div className="col-md-6 mb-2 mt-2">
                        <label htmlFor="inputEmail4" className="form-label my__color">Work Experience<span className='text-danger'>*</span></label>
                        <input type="number" className="form-control" placeholder="Work Experience" id="inputEmail4" onChange={formHandler} value={data.experience} name='experience' />
                    </div>
                    <div className='col-md-6 mb-2 mt-2'>
                        <label className="form-label my__color" htmlFor="inlineFormSelectPref">Qualification</label>
                        <select className="form-select" id="inlineFormSelectPref" onChange={formHandler} value={data.qualification} name='qualification'>
                            <option selected>Select Your Qualification </option>
                            <option value="HighSchool">HighSchool</option>
                            <option value="InterMediate">InterMediate</option>
                            <option value="ITI">ITI</option>
                            <option value="BTech">BTech </option>
                            <option value="Mtech">Mtech </option>
                            <option value="Diploma">Diploma </option>
                            <option value="Other">Other </option>
                        </select>
                    </div>
                    <div className='col-md-12'>

                        <label htmlFor="exampleFormControlTextarea1" className="form-label my__color">Job Description <span className='text-danger'>*</span> </label>

                        <textarea className="form-control" id="exampleFormControlTextarea" rows="4" onChange={formHandler} value={data.description} name='description'></textarea>


                    </div>

                    <div className='col-md-6 mb-2 mt-2'>
                        <div className="col-auto">
                            <button type="submit" className="btn btn-primary mb-3">Submit</button>
                        </div>
                    </div>


                </div>
            </form>
            <ToastContainer />
        </div>

    )
}

export default Staff