import React from 'react'
import BannerImg from './banner.png'
const Banner = () => {
    return (
        <div className='container'>
            <div className='row'>
                <div className='col-md-12'>
                    <img src={BannerImg} className='img-fluid' style={{ width: '100%', objectFit: 'contain' }} />
                </div>
            </div>
        </div>
    )
}

export default Banner