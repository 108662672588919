import React, { useState, useEffect } from 'react'
import init from './firebase'
import { doc, getDoc, collection, query, where } from 'firebase/firestore';
const SellerNumberFinder = ({ user_id }) => {
    const [name, setName] = useState('');

    const merchantFinder = async () => {
        try {
            const res = await getDoc(doc(init.db, "olx_user", user_id));
            setName(res?.data().profile.mobno)
        } catch (error) {
            console.log(`Error : ${error}`)
        }
    }
    useEffect(() => {
        merchantFinder()
    }, [user_id])
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50px', width: '90%', backgroundColor: 'darkorange', marginTop: '5px', color: '#fff', border: 'none', borderRadius: '10px' }}>
            <a href={`tel:${{ name }}`} style={{ textDecoration: 'none', color: '#fff' }}><i className='fa fa-phone'></i> Contact Number</a>
        </div>
    )
}

export default SellerNumberFinder