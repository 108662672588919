import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import init from './firebase'
import { doc, deleteDoc, collection, getDoc, getDocs, where, query } from 'firebase/firestore';
import { useUserAuth } from './context/userAuthContext';
import { AiOutlineHeart, AiTwotoneDelete } from 'react-icons/ai';
import { BiEdit } from 'react-icons/bi'
import { ImLocation2 } from 'react-icons/im';
import { MdDateRange } from 'react-icons/md';
import DateFinder from './DateFinder';
import CityNameFinder from './CityNameFinder';
const Profile = () => {
    const { user } = useUserAuth();
    const merchant_id = user.uid;
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [product, setProduct] = useState([])
    const [merchant, setMerchant] = useState([]);
    const getUserInfo = async () => {
        try {
            const res = await getDoc(doc(init.db, "olx_user", merchant_id));
            setMerchant(res.data());
            // setLoading(false)
        } catch (error) {
            console.log(`Error : ${error}`)
        }
    }
    const getProducts = async () => {
        const mycollection = collection(init.db, 'products');
        let q = query(mycollection, where('merchant_id', '==', merchant_id));
        const data = await getDocs(q);
        setProduct(data.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
    }
    const deletebtnHandler = async (product_id) => {
        const choice = window.confirm('Are you sure want to delete?');
        if (choice) {
            try {
                const result = await deleteDoc(doc(init.db, "products", product_id));
                getProducts()
            } catch (err) {
                console.log(err)
            }
        } else {
            return
        }

    }



    const editbtnHandler = () => {
        alert('ok')
    }
    useEffect(() => {
        if (!user) {
            navigate('/')
        }
        getUserInfo()
        getProducts()
    }, [merchant_id])

    const [myLink, setMyLink] = useState()

    return (
        <div className='container'>
            <div className='row pt-3'>
                <div className='col-md-3'>
                    <div className='card'>
                        <div className='card-header bg-warning text-white'>
                            Profile
                        </div>
                        <div className='card-body'>
                            <div className='d-flex align-items-center justify-content-center flex-column'>
                                <img src="https://e7.pngegg.com/pngimages/799/987/png-clipart-computer-icons-avatar-icon-design-avatar-heroes-computer-wallpaper-thumbnail.png" style={{ height: '50px', width: '50px', borderRadius: '50%' }} />
                                <h4>{merchant?.profile?.name}</h4>

                            </div>
                            <p><i className='fa fa-phone'></i> {merchant?.profile?.mobno}</p>
                            <p><i className='fa fa-envelope'></i> {merchant?.profile?.email}</p>
                            <div className='sidebar text-center'>
                                <nav class="nav flex-column">
                                    <a class="nav-link active" aria-current="page" href="#"></a>
                                    <a class="nav-link" href="#">Post Ad</a>
                                    <a class="nav-link" href="#">Business Listing</a>
                                    <Link class="nav-link" to="/notification">Notification</Link>
                                    <Link class="nav-link" to="/logout">Logout</Link>
                                </nav>
                                <button className='btn btn-success'>Share Profile Link</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-md-9'>
                    <div className='row card__container'>
                        {product?.map((cur, index) => {
                            return (
                                <div className='col-md-4' key={index}>

                                    <div className='prouduct_card'>
                                        <div className="card shadow mb-3">
                                            <Link to={
                                                ''
                                                //     cur?.type == 'new' ? 'new product' : null
                                                //     cur?.type == 'old' ? 'old product' : null
                                                // cur?.type == 'rent' ? 'rent product' : null
                                            }>


                                                <div className='card_img'>
                                                    <img src={cur?.productImages} className="card-img-top" alt="..." />
                                                    <div className='heart_icon'>
                                                        <AiOutlineHeart className='icons' />
                                                    </div>
                                                    <div className='featured'>
                                                        <p>Featured</p>
                                                    </div>
                                                </div>
                                                <div className="card-body">
                                                    <h5 className="card-title">{`${cur.product_name?.length > 25 ? cur?.product_name?.slice(0, 25) + '...' : cur?.product_name}`}</h5>

                                                    <h5 className="card-title"> &#8377;
                                                        {cur?.price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                    </h5>


                                                    <div className='last_row d-flex justify-content-between'>
                                                        <p className='location_row'><ImLocation2 />  <CityNameFinder city_id={cur?.city_id}/></p>
                                                        <p className='date_row'> <MdDateRange />

                                                            <DateFinder mydate={cur.createdAt?.seconds} />
                                                        </p>
                                                    </div>
                                                </div>
                                            </Link>
                                            <div className='last_row d-flex justify-content-around card-footer'>
                                                <Link to={`/edit-product/${cur.id}`}><p className='location_row'><BiEdit size={22} style={{ color: 'green', cursor: 'pointer' }} /> </p></Link>
                                                <p className='date_row'> <AiTwotoneDelete size={22} style={{ color: 'red', cursor: 'pointer' }} onClick={() => deletebtnHandler(cur.id)} />
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Profile