import React, { useState, useEffect } from 'react'
import init from './firebase'
import { doc, getDoc, collection, query, where } from 'firebase/firestore';

const ProductNameFinder = ({ product_id }) => {
    const [name, setName] = useState('');

    const productFinder = async () => {
        try {
            const res = await getDoc(doc(init.db, "olx_products", product_id));
            setName(res.data().product_name)
        } catch (error) {
            console.log(`Error : ${error}`)
        }
    }
    useEffect(() => {
        productFinder()
    }, [product_id])
    return (
        <span>{name}</span>
    )
}

export default ProductNameFinder